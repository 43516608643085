exports.components = {
  "component---src-components-blogs-index-js": () => import("./../../../src/components/blogs/index.js" /* webpackChunkName: "component---src-components-blogs-index-js" */),
  "component---src-components-care-index-js": () => import("./../../../src/components/Care/index.js" /* webpackChunkName: "component---src-components-care-index-js" */),
  "component---src-components-explore-plants-index-js": () => import("./../../../src/components/ExplorePlants/index.js" /* webpackChunkName: "component---src-components-explore-plants-index-js" */),
  "component---src-components-plant-detail-index-js": () => import("./../../../src/components/PlantDetail/index.js" /* webpackChunkName: "component---src-components-plant-detail-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-index-js": () => import("./../../../src/pages/buy/index.js" /* webpackChunkName: "component---src-pages-buy-index-js" */),
  "component---src-pages-hello-index-js": () => import("./../../../src/pages/hello/index.js" /* webpackChunkName: "component---src-pages-hello-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manual-android-app-index-js": () => import("./../../../src/pages/manual-android-app/index.js" /* webpackChunkName: "component---src-pages-manual-android-app-index-js" */),
  "component---src-pages-manual-ios-app-index-js": () => import("./../../../src/pages/manual-ios-app/index.js" /* webpackChunkName: "component---src-pages-manual-ios-app-index-js" */),
  "component---src-pages-manual-refunds-index-js": () => import("./../../../src/pages/manual-refunds/index.js" /* webpackChunkName: "component---src-pages-manual-refunds-index-js" */),
  "component---src-pages-manual-rewards-index-js": () => import("./../../../src/pages/manual-rewards/index.js" /* webpackChunkName: "component---src-pages-manual-rewards-index-js" */),
  "component---src-pages-manual-support-index-js": () => import("./../../../src/pages/manual-support/index.js" /* webpackChunkName: "component---src-pages-manual-support-index-js" */),
  "component---src-pages-manual-video-index-js": () => import("./../../../src/pages/manual-video/index.js" /* webpackChunkName: "component---src-pages-manual-video-index-js" */),
  "component---src-pages-manual-warranty-index-js": () => import("./../../../src/pages/manual-warranty/index.js" /* webpackChunkName: "component---src-pages-manual-warranty-index-js" */),
  "component---src-pages-pages-connect-your-flora-pod-js": () => import("./../../../src/pages/pages/connect-your-flora-pod.js" /* webpackChunkName: "component---src-pages-pages-connect-your-flora-pod-js" */),
  "component---src-pages-pages-hello-js": () => import("./../../../src/pages/pages/hello.js" /* webpackChunkName: "component---src-pages-pages-hello-js" */),
  "component---src-pages-pages-pre-order-terms-js": () => import("./../../../src/pages/pages/pre-order-terms.js" /* webpackChunkName: "component---src-pages-pages-pre-order-terms-js" */),
  "component---src-pages-pages-privacy-js": () => import("./../../../src/pages/pages/privacy.js" /* webpackChunkName: "component---src-pages-pages-privacy-js" */),
  "component---src-pages-pages-shipping-returns-js": () => import("./../../../src/pages/pages/shipping-returns.js" /* webpackChunkName: "component---src-pages-pages-shipping-returns-js" */),
  "component---src-pages-pages-tos-js": () => import("./../../../src/pages/pages/tos.js" /* webpackChunkName: "component---src-pages-pages-tos-js" */),
  "component---src-pages-pre-order-index-js": () => import("./../../../src/pages/preOrder/index.js" /* webpackChunkName: "component---src-pages-pre-order-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-sensor-review-index-js": () => import("./../../../src/pages/sensor-review/index.js" /* webpackChunkName: "component---src-pages-sensor-review-index-js" */),
  "component---src-pages-tos-index-js": () => import("./../../../src/pages/tos/index.js" /* webpackChunkName: "component---src-pages-tos-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/blogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */)
}

